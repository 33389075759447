/* @font-face {
  font-family: "LuckiestGuy";
  src: url("../fonts/LuckiestGuy-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  text-align: left;
  background-color: #000000 !important;
}
.party-no-margin {
  margin: 0;
}
.party-nav-brand {
  font-family: "Luckiest Guy", cursive;
}
.party-navbar-color {
  background-color: #3b488e !important;
}

.party-navbar-link {
  font-weight: 600;
}

.party-navbar-link:hover {
  color: #ffc15b !important;
}
.party-navbar-connect-btn {
  font-weight: 700;
  margin-left: 20px;
  margin-right: 20px;
}
.party-navbar-connect-btn:hover {
  color: #ffffff;
}

.party-footer {
  background-color: #3b488e !important;
  background: #3b488e;
  padding-top: 10px;
  padding-bottom: 10px;
}

.party-footer .footer-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  color: #ffffff;
}

.party-line {
  width: 10%;
  height: 1px;
  border-radius: 3px;
  margin-left: 0;
  background-color: #ef9f4c;
}
.party-line-center {
  width: 100%;
  height: 1px;
  border-radius: 3px;
  background-color: #ef9f4c;
}

.mint-btn-1 {
  border: white !important;
  border-width: 4px !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5) !important;
}
.mint-btn-1-txt {
  font-family: "Luckiest Guy", cursive;
  font-size: 50px;
  line-height: 60px;
}
.mint-not-start-btn {
  background-color: #ff960d;
  background-image: linear-gradient(180deg, #ff960d, #d15705) !important;
}
#mint-not-start-btn.mint-not-start-btn:hover {
  background-color: #3b21af;
  background-image: linear-gradient(180deg, #5f2fac, #3b21af) !important;
}

.coming-soon-txt {
  color: #8b65c9;
}
.party-join-discord-btn {
  background-color: #ff960d;
  background-image: linear-gradient(180deg, #ff960d, #d15705) !important;
}
#party-join-discord-btn.party-join-discord-btn:hover {
  background-color: #3b21af;
  background-image: linear-gradient(180deg, #5f2fac, #3b21af) !important;
}

.white-text {
  color: #ffffff;
}

.biggest-title {
  border: #ffffff;
  border-width: 4px;
  font-size: 100px;
  text-shadow: 0px 3px 5px rgba(168, 161, 161, 0.84);
}
.txt-below-title {
  color: #ffffff;
}

.lucky-font {
  font-family: "Luckiest Guy", cursive;
}
.roadmap-item {
  margin-top: 20px;
  margin-bottom: 20px;
}
.roadmap-milestone {
  font-family: "Luckiest Guy", cursive;
  font-size: large;
  color: #ef9f4c;
}
.roadmap-task {
  color: white;
}

.party-section-title-lg {
  color: white;
  font-family: "Luckiest Guy", cursive;
  font-size: 50px;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
}
.party-section-title {
  color: white;
  font-family: "Luckiest Guy", cursive;
  font-size: 30px;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
}

.section-about {
  background-image: url("/src/assets/img/partyimg/bg_bush.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* Background image is fixed in the viewport so that it doesn’t move when
the content’s height is greater than the image’s height */
  background-attachment: fixed;
  /* Set a background color that will be displayed
while the background image is loading */
  background-color: #3b488e;
  /* height: 100vh; */
}

.section-animation {
}
.section-whyparty {
  background-color: #000958;
  background-image: url("/src/assets/img/partyimg/bg_orange.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-color: #312e7b;
}

.section-rarity {
  background-image: url("/src/assets/img/partyimg/bg_cloud.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #312e7b;
}
.section-roadmap {
}
.section-team {
  background-image: url("/src/assets/img/partyimg/bg_lights4.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-color: #312e7b;
}
.section-team .team1 {
  background-color: #df3d0c;
  background-image: linear-gradient(180deg, #df3d0c, #fc983a);
}
.section-team .team2 {
  background-color: #009ec3;
  background-image: linear-gradient(180deg, #0f8099, #41ccf3);
}
.section-team .team3 {
  background-color: #660e7c;
  background-image: linear-gradient(180deg, #660e7c, #fd247e);
}
.section-team .team4 {
  background-color: #0e7c6e;
  background-image: linear-gradient(180deg, #0e7c6e, #24fd90);
}

.section-contract {
  background-color: #000958;
}
.section-faq {
}
.section-faq .card-header {
  margin-bottom: 15px !important;
  font-size: 14px;
  font-weight: 600;
  color: #000000 !important;
}
.section-faq .card {
  background-color: #ffa034;
}
.section-faq .card .card-body {
  background-color: #333333;
  color: #cccccc !important;
}
.faq-expand-icon {
  color: #ef9f4c;
  font-weight: 700;
}
.contract-box {
  border: #cccccc;
  border-width: 4px;
  border-style: solid;
  border-radius: 8px;
  color: #ffffff;
  font-size: medium;
  background-color: #00000073;
}

.section-whyparty .card {
  background-color: #ffffff8a;
  min-height: 200px;
  font-weight: 600;
  color: #0000006e;
}

.section-whyparty .card-header {
  text-transform: uppercase;
  font-family: "Luckiest Guy", cursive;
  font-size: 20px;
  color: #0e0e0ea8 !important;
  text-shadow: 0px 3px 5px rgba(168, 161, 161, 0.84);
}
.section-social {
  padding-top: 0 !important;
  padding-bottom: 20px !important;
}
